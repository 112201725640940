<script>
import {mapActions, mapGetters, mapState} from "vuex";

import Layout from "../layouts/main";
import Preloader from "@/components/widgets/preloader";
import Profile from "@/components/account/account-profile-logged";

/**
     * Dashboard-1 Component
     */
    export default {
        components: { Layout, Profile, Preloader},
        data() {
            return {
                title: "Dashboard",
                preloader: true,
                orgName: process.env.VUE_APP_ORG_NAME
            };
        },
        computed: {
            ...mapState({
                auth: state => state.auth,
                users: state => state.users.all
            }),
        },
        mounted() {
            this.preloader = false;
        },
        methods: {
            ...mapGetters("auth", {
                getUser: "getUser"
            }),
            ...mapActions("users", {
                getAllUsers: "getAll",
                deleteUser: "delete"
            })
        }
    };
</script>

<template>
    <Layout>
        <preloader v-if="preloader"></preloader>

        <!-- start page title -->
        <div class="row">
            <div class="col-12">
                <div class="page-title-box d-flex align-items-center justify-content-between">
                    <h4 class="mb-0 font-size-18">Dashboard</h4>

                    <div class="page-title-right">
                        <ol class="breadcrumb m-0">
                            <li class="breadcrumb-item active"></li>
                        </ol>
                    </div>
                </div>
            </div>
        </div>
        <!-- end page title -->

        <div class="row">
            <div class="col-xl-5">
                <Profile style="height: 305px;"/>
            </div>
            <!-- end col -->
            <div class="col-xl-7">
                <div class="card bg-soft-primary">
                    <div>
                        <div v-if="orgName === 'Fundacja Zobacz Mnie'" class="row">
                            <div class="col-7">
                                <div class="text-primary p-3">
                                    <h5 class="text-primary">FUNDACJA ZOBACZ MNIE</h5>
                                    <p></p>
                                    <p>
                                        ul. Ofiar Oświęcimskich 14 /11<br>
                                        50 -069 Wrocław<br>
                                        Biuro czynne od poniedziałku do piątku: 8.00-16.00
                                    </p>
                                    <p>
                                        Tel.: 71 757 50 08<br>
                                        Strona WWW: <a href="https://zobaczmnie.org">https://zobaczmnie.org</a><br>
                                        E-mail: <a href="mailto:fundacja@zobaczmnie.org">fundacja@zobaczmnie.org</a>
                                    </p>
                                    <p>
                                        <b>Konto wpłat imiennych:</b><br>
                                        W tytule prosimy wpisać imię i nazwisko wybranego Podopiecznego:<br><br>
                                        28 1090 23 98 0000 0001 4358 4104
                                    </p>
                                </div>
                            </div>
                            <div class="col-5 align-self-end">
                                <img src="@/assets/images/profile-img.png" alt class="img-fluid" />
                            </div>
                        </div>

                      <div v-if="orgName === 'Fundacja Na Ratunek'" class="row">
                        <div class="col-7">
                          <div class="text-primary p-3">
                            <h5 class="text-primary">Na Ratunek Dzieciom z Chorobą Nowotworową</h5>
                            <p></p>
                            <p>
                              ul. Ślężna 114s/1<br>
                              53-111 Wrocław<br>
                              Biuro czynne od poniedziałku do piątku: 8.00-16.00
                            </p>
                            <p>
                              Tel.: 71 712 77 33<br>
                              Strona WWW: <a href="https://naratunek.org/">https://naratunek.org/</a><br>
                              E-mail: <a href="mailto:fundacja@naratunek.org">fundacja@naratunek.org</a>
                            </p>
                            <p>
                              <b>Konto wpłat imiennych:</b><br>
                              W tytule prosimy wpisać imię i nazwisko wybranego Podopiecznego:<br><br>
                              97 1160 2202 0000 0000 9394 2103
                            </p>
                          </div>
                        </div>
                        <div class="col-5 align-self-end">
                          <img src="@/assets/images/profile-img.png" alt class="img-fluid" />
                        </div>
                      </div>

                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-5">
                <div class="card">
                    <div class="card-body">
                        <h4 class="card-title mb-4">Nasze media społecznościowe</h4>
                        <div class="text-center">
                            <a href="https://www.facebook.com/fundacjazobaczmnie/">
                                <div class="avatar-sm mx-auto mb-4">
                  <span class="avatar-title rounded-circle bg-primary font-size-18">
                    <i class="mdi mdi-facebook text-white"></i>
                  </span>
                                </div>
                                <p class="font-16 text-muted mb-2"></p>
                                <h5>Facebook</h5>
                            </a>
                        </div>
                        <div class="row mt-4">
                            <div class="col-sm-4">
                                <a href="https://www.linkedin.com/company/fundacja-zobacz-mnie/">
                                    <div class="social-source text-center mt-3">
                                        <div class="avatar-xs mx-auto mb-3">
                      <span class="avatar-title rounded-circle bg-primary font-size-16">
                        <i class="mdi mdi-linkedin text-white"></i>
                      </span>
                                        </div>
                                        <h5 class="font-size-15">LinkedIn</h5>
                                    </div>
                                </a>
                            </div>
                            <div class="col-sm-4">
                                <div class="social-source text-center mt-3">
                                    <a href="https://www.instagram.com/fundacjazobaczmnie/?hl=pl">
                                        <div class="avatar-xs mx-auto mb-3">
                      <span class="avatar-title rounded-circle bg-pink font-size-16">
                        <i class="mdi mdi-instagram text-white"></i>
                      </span>
                                        </div>
                                        <h5 class="font-size-15">Instagram</h5>
                                    </a>
                                </div>
                            </div>
                            <div class="col-sm-4">
                                <div class="social-source text-center mt-3">
                                    <div class="avatar-xs mx-auto mb-3">
                    <span class="avatar-title rounded-circle bg-info font-size-16">
                      <i class="mdi mdi-twitter text-white"></i>
                    </span>
                                    </div>
                                    <h5 class="font-size-15">Twitter</h5>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
            <!-- end col -->
        </div>
        <!-- end row -->

    </Layout>
</template>
