<script>
import {mapState} from "vuex";
import SubaccountSearchTopbar from "@/components/subaccount/subaccount-search-topbar";
import UnreadMessageCount from "@/components/message/unread-message-count.vue";
import {SubaccountContextService} from "@/app/service/subaccount-context/subaccount-context-service";

export default {
  components: {
    SubaccountSearchTopbar, UnreadMessageCount
  },
  computed: {
    ...mapState({
      authState: state => state.auth
    }),
  },
  created() {
    this.subaccountContextId = SubaccountContextService.getSubaccountContextId();
  },
  data() {
    return {
      logoPath: require(process.env.VUE_APP_LOGO_MAIN_PATH),
      messageModuleEnabled: process.env.VUE_APP_MODULE_MESSAGE_ENABLED,
      subaccountContextId: null
    }
  },
  methods: {
    toggleRightSidebar() {
      this.$parent.toggleRightSidebar();
    },
    toggleMenu() {
      let element = document.getElementById("topnav-menu-content");
      element.classList.toggle("show");
    },
    initFullScreen() {
      document.body.classList.toggle("fullscreen-enable");
      if (
        !document.fullscreenElement &&
        /* alternative standard method */ !document.mozFullScreenElement &&
        !document.webkitFullscreenElement
      ) {
        // current working methods
        if (document.documentElement.requestFullscreen) {
          document.documentElement.requestFullscreen();
        } else if (document.documentElement.mozRequestFullScreen) {
          document.documentElement.mozRequestFullScreen();
        } else if (document.documentElement.webkitRequestFullscreen) {
          document.documentElement.webkitRequestFullscreen(
            Element.ALLOW_KEYBOARD_INPUT
          );
        }
      } else {
        if (document.cancelFullScreen) {
          document.cancelFullScreen();
        } else if (document.mozCancelFullScreen) {
          document.mozCancelFullScreen();
        } else if (document.webkitCancelFullScreen) {
          document.webkitCancelFullScreen();
        }
      }
    }
  }
};
</script>

<template>
  <header id="page-topbar">
    <div class="navbar-header">
      <div class="d-flex">
        <!-- LOGO -->
        <div class="navbar-brand-box">
          <router-link to="/" class="logo logo-dark">
            <span class="logo-sm">
              <img :src="logoPath" alt height="16" />
            </span>
            <span class="logo-lg">
              <img :src="logoPath" alt height="40" />
            </span>
          </router-link>

          <router-link to="/" class="logo logo-light">
            <span class="logo-sm">
              <img :src="logoPath" alt height="16" />
            </span>
            <span class="logo-lg">
              <img :src="logoPath" alt height="40" />
            </span>
          </router-link>
        </div>

        <button
          id="toggle"
          type="button"
          class="btn btn-sm mr-2 font-size-16 d-none header-item"
          @click="toggleMenu"
        >
          <i class="fa fa-fw fa-bars"></i>
        </button>

       <!-- App Search-->
          <SubaccountSearchTopbar class="d-block"/>
      </div>

      <div class="d-flex">

        <div class="dropdown d-none d-lg-inline-block ml-1">
          <button type="button" class="btn header-item noti-icon" @click="initFullScreen">
            <i class="bx bx-fullscreen"></i>
          </button>
        </div>

        <b-dropdown right variant="black" toggle-class="header-item">
          <template v-slot:button-content>
            <unread-message-count style="margin-top: 15px;"/>
            <img v-if="authState.user.profileImageUrl.length > 0"
              class="rounded-circle header-profile-user"
              v-bind:src="authState.user.profileImageUrl"
              alt="Header Avatar"
            />
            <img v-if="authState.user.profileImageUrl.length == 0"
              class="rounded-circle header-profile-user"
              src="@/assets/images/users/avatar-1.jpg"
              alt="Header Avatar"
            />


            <span class="d-none d-xl-inline-block ml-1">{{ authState.user.firstName }}</span>
            <i class="mdi mdi-chevron-down d-none d-xl-inline-block"></i>
          </template>
          <!-- item-->
            <b-link :to="{ name: 'Home' }">
                <div class="dropdown-item">
                    <i class="bx bx-home font-size-16 align-middle mr-1"></i> Dashboard
                </div>
            </b-link>

          <span v-if="messageModuleEnabled === 'true'">
            <b-link :to="{ path: '/message/list/inbox' }">
              <div class="dropdown-item">
                <i class="bx bx-message-dots font-size-16 align-middle mr-1"></i> Wiadomości
                <unread-message-count/>
              </div>
            </b-link>
          </span>

          <b-link :to="{ name: 'Profil podopiecznego', params: { id: this.subaccountContextId } }">
            <div class="dropdown-item">
              <i class="bx bx-money font-size-16 align-middle mr-1"></i> Saldo i transakcje
            </div>
          </b-link>

          <b-link :to="{ name: 'Profil pracownika', params: { id: authState.user.uuid } }">
            <div class="dropdown-item">
              <i class="bx bx-user font-size-16 align-middle mr-1"></i> Twój profil
            </div>
          </b-link>

          <b-link :to="{ name: 'Profil pracownika', params: { id: authState.user.uuid } }">
            <div class="dropdown-item d-block">
                <i class="bx bx-wrench font-size-16 align-middle mr-1"></i> Zmień hasło
            </div>
          </b-link>

          <div class="dropdown-divider"></div>
          <b-link :to="{ name: 'Wyloguj' }">
            <div class="dropdown-item text-danger">
              <i class="bx bx-power-off font-size-16 align-middle mr-1 text-danger"></i> Wyloguj
            </div>
          </b-link>
        </b-dropdown>


      </div>
    </div>
  </header>
</template>
