<script>
import Repository from "../../app/repository/repository-factory";
import {SubaccountContextService} from '@/app/service/subaccount-context/subaccount-context-service';

const SubaccountRepository = Repository.get("SubaccountRepository");

export default {
  components: {},
  data() {
    return {
      preloader: true,
      selected: null,
      showSearchBar: true,
      options: []
    }
  },
  created() {
    this.getQuickSearchSubaccount();
  },
  methods: {
    liveSearch() {
      return this.options;
    },
    getQuickSearchSubaccount() {
      SubaccountRepository.getQuickSearch().then((response) => {
        this.options = response.data;
        let subaccountContextId = SubaccountContextService.getSubaccountContextId();
        if (subaccountContextId !== null) {
          let founded = this.options.find(d => d.uuid === subaccountContextId);
          if (founded) {
            this.selected = founded.fullName;
            this.showSearchBar = false;
          }
        }

      }).catch(error => {
        console.log(error);
      })
    },
    changeSubaccountContext() {
      this.showSearchBar = true;
      this.selected = null;
    },
    getSubaccountInitials() {
      let names = this.selected.split(' '),
          initials = names[0].substring(0, 1).toUpperCase();

      if (names.length > 1) {
        initials += names[names.length - 1].substring(0, 1).toUpperCase();
      }
      return initials;
    },
    redirectToSubaccount() {
      let i;
      for (i = 0; i < this.options.length; i++) {
        if (this.selected === this.options[i].fullName) {
          this.$router.push({name: "Profil podopiecznego", params: {id: this.options[i].uuid}});
          //this.selected = '';
          SubaccountContextService.addSubaccountContextId(this.options[i].uuid);
          this.showSearchBar = false;
        }
      }
    }
  }
};
</script>

<template>
  <div class="d-flex" v-if="options.length >= 1">
    <template v-if="selected !== null && showSearchBar === false">
      <div @click="changeSubaccountContext()" class="d-flex">
        <b-avatar button  variant="secondary" :text="getSubaccountInitials()" class="my-auto"></b-avatar>
        <span role="button" class="my-auto ml-2 text-muted font-weight-bold">
          {{ selected }}
          <i class="mdi mdi-chevron-down" v-if="options.length > 1"></i>
        </span>
      </div>
    </template>

    <form class="app-search d-block" v-if="selected === null || showSearchBar === true">
      <div class="position-relative">
          <b-form-input
              v-on:change="redirectToSubaccount"
              list="input-list"
              v-model="selected"
              id="input-with-list"
              placeholder="Wybierz podopiecznego..."
          ></b-form-input>

          <b-form-datalist
              id="input-list"
              value-field="fullName"
              html-field="fullName"
              :options="liveSearch()"
          >
          </b-form-datalist>
          <span class="bx bx-search-alt"></span>
      </div>
    </form>
  </div>
</template>
