<script>
import Repository from "../../app/repository/repository-factory";
import EventBus from "@/event-bus";
import {SubaccountContextService} from '@/app/service/subaccount-context/subaccount-context-service';

const MessageRepository = Repository.get("MessageRepository");

export default {
  components: {},
  data() {
    return {
      count: 0,
      timer: "",
    }
  },
  created() {
    this.getQuickSearchSubaccount();
    this.timer = setInterval(this.getQuickSearchSubaccount, 30 * 1000);
      EventBus.$on('UnreadMessageCountChanged', (countUnreadMessages) => {
          this.count = countUnreadMessages;
      });
  },
  beforeDestroy() {
    this.cancelAutoUpdate();
  },
  methods: {
    getQuickSearchSubaccount() {
      if (SubaccountContextService.getSubaccountContextId() === null) {
        this.count = 0;
        console.log('Brak kontekstu subkonta do pobrania liczby nieprzeczytanych wiadomości');
        return;
      }

      MessageRepository.countUnread().then((response) => {
        this.count = response.data.count;
        EventBus.$emit('UnreadMessageCountChanged', this.count);
      }).catch(error => {
        console.log(error);
      })
    },
    cancelAutoUpdate() {
      clearInterval(this.timer);
    },
  }
};
</script>

<template>
  <b-badge v-if="count > 0" variant="danger" class="position-absolute" style="margin-left: 5px; margin-top: 2px;">
    {{ count }}</b-badge>
</template>

<style scoped>

</style>
