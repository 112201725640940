<script>
import {AuthJwtService} from '@/app/service/auth/auth-jwt-service';
import UnreadMessageCount from "@/components/message/unread-message-count";
import {SubaccountContextService} from '@/app/service/subaccount-context/subaccount-context-service';

export default {
  components: {UnreadMessageCount},
  data() {
    return {
      subaccountContextId: null,
      messageModuleEnabled: process.env.VUE_APP_MODULE_MESSAGE_ENABLED
    }
  },
  created() {
    this.subaccountContextId = SubaccountContextService.getSubaccountContextId();
  },
  methods: {
    isAdmin() {
      return AuthJwtService.isGranted('ROLE_ADMIN');
    },
    onMenuClick(event) {
      event.preventDefault();
      const nextEl = event.target.nextSibling;
      if (nextEl && !nextEl.classList.contains("show")) {
        const parentEl = event.target.parentNode;
        if (parentEl) {
          parentEl.classList.remove("show");
        }
        nextEl.classList.add("show");
      } else if (nextEl) {
        nextEl.classList.remove("show");
      }
      return false;
    }
  }
};
</script>
<template>
  <div class="topnav">
    <div class="container-fluid">
      <nav class="navbar navbar-light navbar-expand-lg topnav-menu">
        <div id="topnav-menu-content" class="collapse navbar-collapse">
          <ul class="navbar-nav">

            <li class="nav-item">
              <router-link tag="a" class="nav-link" to="/">
                <i class="bx bx-home-circle mr-2"></i>Dashboard
              </router-link>
            </li>

            <li class="nav-item">
              <router-link tag="a" class="nav-link" :to="{ name: 'Profil podopiecznego', params: { id: subaccountContextId }}">
                <span><i class="bx bx-face mr-2"></i> Saldo i transakcje</span>
              </router-link>
            </li>

            <li v-if="messageModuleEnabled === 'true'" class="nav-item">
              <router-link tag="a" class="nav-link" to="/message/list/inbox">
                <span><i class="bx bx-message-dots mr-2"></i>Wiadomości
                <unread-message-count/></span>
              </router-link>
            </li>

          </ul>
        </div>
      </nav>
    </div>
  </div>
</template>
